//
// Icon
//

.icon {
    width: $icon-size;
    height: $icon-size;

    i, svg {
        font-size: $icon-size - .75;
    }

    + .icon-text {
        padding-left: 1rem;
        width: calc(100% - #{$icon-size} - 1);
    }
}


// Extra large icons

.icon-xl {
    width: $icon-size-xl;
    height: $icon-size-xl;

    i, svg {
        font-size: $icon-size-xl - .75;
    }

    + .icon-text {
        width: calc(100% - #{$icon-size-xl} - 1);
    }
}


// Large icons

.icon-lg {
    width: $icon-size-lg;
    height: $icon-size-lg;

    i, svg {
        font-size: $icon-size-lg - .75;
    }

    + .icon-text {
        width: calc(100% - #{$icon-size-lg} - 1);
    }
}


// Small icon

.icon-sm {
    width: $icon-size-sm;
    height: $icon-size-sm;

    i, svg {
        font-size: $icon-size-sm - .75;
    }

    + .icon-text {
        width: calc(100% - #{$icon-size-sm} - 1);
    }
}
