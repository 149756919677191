// Custom variables

@import "./modules/variables";

// Vendors

@import "vendors/argon-dashboard";

@import "~select2/dist/css/select2.css";
@import "./modules/select2-bootstrap4";

@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";

// custom

@import "partials/all";

$tandemBlue: #2daae1;

.navbar {
    padding: 0;
}
.logoContainer {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
    margin: 0 auto;
}
.logoContainer img {
    width: 190px;
}

.title1 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 55px;
    line-height: 50px;
    font-family: "Roboto", sans-serif;
}
.title2 {
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-size: 23.5px;
}
.btn-link {
    color: $tandemBlue;
}
.btn-info,
.btn-primary {
    background-color: $tandemBlue;
    border-color: $tandemBlue;
}
.titleInfos {
    color: #ffffff;
    margin-bottom: 40px;
    font-size: 18px;
}
.intro {
    width: 450px;
    margin-top: 30px;
}
.custom-radio {
    display: inline-block;
}

a,
.nav-link:hover {
    color: $tandemBlue;
}
a:hover {
    color: #2d82e1;
}
.btn-primary:hover {
    background-color: #2d82e1;
    border-color: #2d82e1;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $tandemBlue;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $tandemBlue;
}
.mrm {
    margin-right: 20px;
}
.mtm {
    margin-top: 20px;
}
.mbm {
    margin-bottom: 20px;
}

select.form-control {
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0;
}

.bg-gradient-primary {
    background: linear-gradient(87deg, #2d82e1 0, $tandemBlue 100%) !important;
    padding-bottom: 40px;
}
.loginLogoContainer {
    display: inline-block;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}
.loginLogoContainer img {
    width: 200px;
}

.navbar-brand-img {
    margin-top: 20px;
}
.navbar-dark .navbar-nav .nav-link {
    margin-top: 5px;
}
.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
    max-height: 3rem;
}

.pageTitle {
    color: #fff;
    font-size: 20px;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
}

.card.nb {
    border: 0;
}

.card-header.forTitle {
    padding: 15px 30px;
    overflow: hidden;
    background: $tandemBlue;
    h3 {
        color: #fff;
        font-size: 20px;
    }
}

.accordion .btn-link {
    padding: 0;
    text-align: left;
    width: 100%;
    &:hover {
        color: #32325d;
    }
}

.table-responsive {
    min-height: 220px;
}

.fo .select2-container {
    width: 100% !important;
}
.select2-container .select2-selection--single,
.form-control {
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.select2-selection__rendered,
.form-control {
    color: #32325d !important;
}
.select2-container .select2-results__option--highlighted[aria-selected] {
    background-color: $tandemBlue;
}
.select2-container .select2-dropdown {
    border: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $tandemBlue;
}
label {
    font-size: 14px;
}
.small,
small {
    font-size: 72%;
}
.heading-small {
    color: $tandemBlue;
}

footer {
    margin-top: 50px;
    .nav-link span {
        margin-left: 20px;
    }
}

@media screen and (max-width: 991px) {
    .logoContainer img {
        width: 180px;
    }
    .intro {
        margin-top: 100px;
    }
    .nav-link {
        font-size: 13px;
    }
}

@media screen and (max-width: 499px) {
    .intro {
        width: auto;
    }
    .title1 {
        font-size: 38px;
        line-height: 38px;
    }
    .title2 {
        font-size: 20px;
        line-height: 25px;
    }
    .navbar-brand-img {
        margin: 10px 0;
    }
    .navbar-collapse .collapse-brand img {
        height: 45px;
    }
}

@media screen and (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 80px;
    }
}

#mentorRegister {
    label.required:after {
        content: " *";
    }
}

ul.navbar-nav {
    .nav-item.disabled {
        cursor: not-allowed;

        a {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
