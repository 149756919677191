#navbar-main .breadcrumb {
    margin: 0;
    background-color: transparent;

    .breadcrumb-item {
        + .breadcrumb-item {
            &::before {
                color: rgba($white, .4);
            }
        }
    }
}
