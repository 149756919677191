/*!
 * Select2 Bootstrap Theme v0.2.0-beta.4 (https://angel-vladov.github.io/select2-bootstrap-theme)
 * Copyright 2015-2019 Florian Kissling and contributors (https://github.com/select2/select2-bootstrap-theme/graphs/contributors)
 * Licensed under MIT (https://github.com/select2/select2-bootstrap-theme/blob/master/LICENSE)
 */

/**
 * We need a clone of bootstrap color-yiq mixin so we can get the same value for color
 */
.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
}

.select2-container .select2-selection {
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-color: #fff;
  border: 1px solid $input-border-color;
  color: #8898aa;
  font-size: 1rem;
  outline: 0;
}

@media (prefers-reduced-motion: reduce) {
  .select2-container .select2-selection {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.select2-container .select2-selection.form-control {
  border-radius: 0.25rem;
}

.select2-container .select2-search--dropdown .select2-search__field {
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-color: #fff;
  border: 1px solid $input-border-color;
  color: #8898aa;
  font-size: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .select2-container .select2-search--dropdown .select2-search__field {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.select2-container .select2-search__field {
  outline: 0;
  /* Firefox 18- */
  /**
     * Firefox 19+
     *
     * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
     */
}

.select2-container .select2-search__field::-webkit-input-placeholder {
  color: #6c757d;
}

.select2-container .select2-search__field:-moz-placeholder {
  color: #6c757d;
}

.select2-container .select2-search__field::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.select2-container .select2-search__field:-ms-input-placeholder {
  color: #6c757d;
}

.select2-container .select2-results__option {
  padding: 0.375rem 0.75rem;
  min-height: 28px;
}

.select2-container .select2-results__option[role=group] {
  padding: 0;
}

.select2-container .select2-results__option[aria-disabled=true] {
  color: #6c757d;
  cursor: not-allowed;
}

.select2-container .select2-results__option[aria-selected=true] {
  background-color: #f8f9fa;
  color: #16181b;
}

.select2-container .select2-results__option--highlighted[aria-selected] {
  background-color: #007bff;
  color: #fff;
}

.select2-container .select2-results__option .select2-results__option {
  padding: 0.375rem 0.75rem;
}

.select2-container .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -0.75rem;
  padding-left: 1.5rem;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1.5rem;
  padding-left: 2.25rem;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2.25rem;
  padding-left: 3rem;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3rem;
  padding-left: 3.75rem;
}

.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3.75rem;
  padding-left: 4.5rem;
}

.select2-container .select2-results__group {
  color: #6c757d;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  white-space: nowrap;
}

.select2-container.select2-container--focus .select2-selection, .select2-container.select2-container--open .select2-selection {
  border-color: #80bdff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select2-container.select2-container--open {
  /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */
  /**
     * Handle border radii of the container when the dropdown is showing.
     */
}

.select2-container.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #6c757d transparent;
  border-width: 0 0.25rem 0.25rem 0.25rem;
}

.select2-container.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-color: transparent;
  //-webkit-box-shadow: none;
  //        box-shadow: none;
}

.select2-container.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.select2-container .select2-selection__clear {
  color: #6c757d;
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container .select2-selection__clear:hover {
  color: "#111";
}

.select2-container.select2-container--disabled .select2-selection {
  border-color: #ced4da;
}

.select2-container.select2-container--disabled .select2-selection,
.select2-container.select2-container--disabled .select2-search__field {
  cursor: not-allowed;
}

.select2-container.select2-container--disabled .select2-selection,
.select2-container.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: #e9ecef;
}

.select2-container.select2-container--disabled .select2-selection__clear,
.select2-container.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
  display: none;
}

.select2-container .select2-dropdown {
  border-color: #80bdff;
  overflow-x: hidden;
  margin-top: -1px;
}

.select2-container .select2-dropdown--above {
  margin-top: 1px;
}

.select2-container .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgba(0,0,0,0.2);
}

.select2-container .select2-selection--single {
  height: -webkit-calc(2.75rem + 2px);
  height: calc(2.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  /**
     * Adjust the single Select2's dropdown arrow button appearance.
     */
}

.select2-container .select2-selection--single .select2-selection__arrow {
  position: absolute;
  bottom: 0;
  right: 0.75rem;
  top: 0;
  width: 0.25rem;
  height: inherit;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #6c757d transparent transparent transparent;
  border-style: solid;
  border-width: 0.25rem 0.25rem 0 0.25rem;
  height: 0;
  left: 0;
  margin-left: -0.25rem;
  margin-top: -0.125rem;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  color: #8898aa;
  padding: 0;
}

.select2-container .select2-selection--single .select2-selection__placeholder {
  color: #6c757d;
}

.select2-container .select2-selection--multiple {
  min-height: -webkit-calc(2.75rem + 2px);
  min-height: calc(2.75rem + 2px);
  padding: 0;
  height: auto;
  /**
     * Make Multi Select2's choices match Bootstrap 4's default button styles.
     */
  /**
     * Minus 2px borders.
     */
  /**
     * Clear the selection.
     */
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--multiple .select2-selection__placeholder {
  color: #6c757d;
  float: left;
  margin-top: 5px;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  color: #8898aa;
  background: #e9ecef;
  border: 1px solid $input-border-color;
  border-radius: 0.25rem;
  cursor: default;
  float: left;
  margin: -webkit-calc(0.375rem - 1px) 0 0 0.375rem;
  margin: calc(0.375rem - 1px) 0 0 0.375rem;
  padding: 0 0.375rem;
}

.select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
  background: transparent;
  padding: 0 0.75rem;
  height: -webkit-calc(2.75rem + 2px);
  height: calc(2.75rem + 2px);
  line-height: 1.5;
  margin: -1px 0;
  min-width: 5em;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #6c757d;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.1875rem;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: "#111";
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.375rem;
}

.select2-container .select2-selection--single.form-control-sm,
.input-group-sm .select2-container .select2-selection--single,
.form-group-sm .select2-container .select2-selection--single {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: -webkit-calc(2.75rem + 2px);
  height: calc(2.75rem + 2px);
  line-height: 1.5;
  padding: 0.25rem 1.25rem 0.25rem 0.5rem;
  /* 2 */
}

.select2-container .select2-selection--single.form-control-sm .select2-selection__arrow b,
.input-group-sm .select2-container .select2-selection--single .select2-selection__arrow b,
.form-group-sm .select2-container .select2-selection--single .select2-selection__arrow b {
  margin-left: -0.25rem;
}

.select2-container .select2-selection--multiple.form-control-sm,
.input-group-sm .select2-container .select2-selection--multiple,
.form-group-sm .select2-container .select2-selection--multiple {
  border-radius: 0.2rem;
  min-height: -webkit-calc(2.75rem + 2px);
  min-height: calc(2.75rem + 2px);
}

.select2-container .select2-selection--multiple.form-control-sm .select2-selection__choice,
.input-group-sm .select2-container .select2-selection--multiple .select2-selection__choice,
.form-group-sm .select2-container .select2-selection--multiple .select2-selection__choice {
  font-size: 0.875rem;
  line-height: 1.5;
  margin: -webkit-calc(0.25rem - 1px) 0 0 0.25rem;
  margin: calc(0.25rem - 1px) 0 0 0.25rem;
  padding: 0 0.25rem;
}

.select2-container .select2-selection--multiple.form-control-sm .select2-search--inline .select2-search__field,
.input-group-sm .select2-container .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-sm .select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0 0.5rem;
  font-size: 0.875rem;
  height: -webkit-calc(2.75rem + 2px);
  height: calc(2.75rem + 2px);
  line-height: 1.5;
}

.select2-container .select2-selection--multiple.form-control-sm .select2-selection__clear,
.input-group-sm .select2-container .select2-selection--multiple .select2-selection__clear,
.form-group-sm .select2-container .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.25rem;
}

.select2-container .select2-selection--single.form-control-lg,
.input-group-lg .select2-container .select2-selection--single,
.form-group-lg .select2-container .select2-selection--single {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: -webkit-calc(1.5em + 1rem + 2px);
  height: calc(1.5em + 1rem + 2px);
  line-height: 1.5;
  padding: 0.5rem 1.9375rem 0.5rem 1rem;
  /* 1 */
}

.select2-container .select2-selection--single.form-control-lg .select2-selection__arrow,
.input-group-lg .select2-container .select2-selection--single .select2-selection__arrow,
.form-group-lg .select2-container .select2-selection--single .select2-selection__arrow {
  width: 0.3125rem;
}

.select2-container .select2-selection--single.form-control-lg .select2-selection__arrow b,
.input-group-lg .select2-container .select2-selection--single .select2-selection__arrow b,
.form-group-lg .select2-container .select2-selection--single .select2-selection__arrow b {
  border-width: 0.3125rem 0.3125rem 0 0.3125rem;
  margin-left: -0.3125rem;
  margin-left: -0.5rem;
  margin-top: -0.15625rem;
}

.select2-container .select2-selection--multiple.form-control-lg,
.input-group-lg .select2-container .select2-selection--multiple,
.form-group-lg .select2-container .select2-selection--multiple {
  min-height: -webkit-calc(1.5em + 1rem + 2px);
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: 0.3rem;
}

.select2-container .select2-selection--multiple.form-control-lg .select2-selection__choice,
.input-group-lg .select2-container .select2-selection--multiple .select2-selection__choice,
.form-group-lg .select2-container .select2-selection--multiple .select2-selection__choice {
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: -webkit-calc(0.5rem - 1px) 0 0 0.5rem;
  margin: calc(0.5rem - 1px) 0 0 0.5rem;
  padding: 0 0.5rem;
}

.select2-container .select2-selection--multiple.form-control-lg .select2-search--inline .select2-search__field,
.input-group-lg .select2-container .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-lg .select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0 1rem;
  font-size: 1.25rem;
  height: -webkit-calc(1.5em + 1rem + 2px);
  height: calc(1.5em + 1rem + 2px);
  line-height: 1.5;
}

.select2-container .select2-selection--multiple.form-control-lg .select2-selection__clear,
.input-group-lg .select2-container .select2-selection--multiple .select2-selection__clear,
.form-group-lg .select2-container .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.5rem;
}

.select2-container .select2-selection.form-control-lg.select2-container--open .select2-selection--single {
  /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */
}

.select2-container .select2-selection.form-control-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #6c757d transparent;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}

.input-group-lg .select2-container .select2-selection.select2-container--open .select2-selection--single {
  /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */
}

.input-group-lg .select2-container .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #6c757d transparent;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}

.select2-container[dir="rtl"] {
  /**
     * Single Select2
     *
     * 1. Makes sure that .select2-selection__placeholder is positioned
     *    correctly.
     */
  /**
     * Multiple Select2
     */
}

.select2-container[dir="rtl"] .select2-selection--single {
  padding-left: 1.5rem;
  padding-right: 0.75rem;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
  /* 1 */
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 0.75rem;
  right: auto;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__arrow b {
  margin-left: 0;
}

.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 0;
  margin-right: 0.375rem;
}

.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container .select2-dropdown[dir="rtl"] .select2-results__options {
  text-align: right;
}

/*------------------------------------*\
  #ADDITIONAL GOODIES
\*------------------------------------*/
/**
 * Address Bootstrap's validation states
 *
 * If a Select2 widget parent has one of Bootstrap's validation state modifier
 * classes, adjust Select2's border colors and focus states accordingly.
 * You may apply said classes to the Select2 dropdown (body > .select2-container)
 * via JavaScript match Bootstraps' to make its styles match.
 *
 * @see https://getbootstrap.com/docs/4.0/components/forms/#validation
 */
.is-valid.select2-drop-active {
  border-color: #1e7e34;
}

.is-valid.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #1e7e34;
}

.is-invalid .select2-dropdown,
.is-invalid .select2-selection {
  border-color: #dc3545;
}

.is-invalid .select2-container--focus .select2-selection,
.is-invalid .select2-container--open .select2-selection {
  border-color: #bd2130;
}

.is-invalid .select2-container--focus .select2-selection:focus,
.is-invalid .select2-container--open .select2-selection:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.is-invalid.select2-drop-active {
  border-color: #bd2130;
}

.is-invalid.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #bd2130;
}

/* Validation classes on parent element. Preserved Bootstrap 3 validation classes */
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ffc107;
}

.has-warning .select2-container--focus .select2-selection,
.has-warning .select2-container--open .select2-selection {
  border-color: #d39e00;
}

.has-warning .select2-container--focus .select2-selection:focus,
.has-warning .select2-container--open .select2-selection:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}

.has-warning.select2-drop-active {
  border-color: #d39e00;
}

.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #d39e00;
}

.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #dc3545;
}

.has-error .select2-container--focus .select2-selection,
.has-error .select2-container--open .select2-selection {
  border-color: #bd2130;
}

.has-error .select2-container--focus .select2-selection:focus,
.has-error .select2-container--open .select2-selection:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.has-error.select2-drop-active {
  border-color: #bd2130;
}

.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #bd2130;
}

.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #28a745;
}

.has-success .select2-container--focus .select2-selection,
.has-success .select2-container--open .select2-selection {
  border-color: #1e7e34;
}

.has-success .select2-container--focus .select2-selection:focus,
.has-success .select2-container--open .select2-selection:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.has-success.select2-drop-active {
  border-color: #1e7e34;
}

.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #1e7e34;
}

/**
 * Select2 widgets in Bootstrap Input Groups
 *
 * @see https://getbootstrap.com/docs/4.0/components/input-group/
 * @see https://github.com/twbs/bootstrap/blob/v4.0.0-beta.2/scss/_input-group.scss
 */
/**
 * Reset rounded corners
 */
.input-group > .select2-hidden-accessible:first-child + .select2-container > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:first-child + .select2-container > .selection > .select2-selection.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) > .selection > .select2-selection.form-control {
  border-radius: 0;
}

.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container:last-child > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container:last-child > .selection > .select2-selection.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .select2-container {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
  z-index: 2;
  width: 1%;
  margin-bottom: 0;
  /**
   * Adjust z-index like Bootstrap does to show the focus-box-shadow
   * above appended buttons in .input-group and .form-group.
   */
  /**
   * Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
   * Multi Select2's height which - depending on how many elements have been selected -
   * may grow taller than its initial size.
   *
   * @see https://github.com/twbs/bootstrap/blob/v4.0.0-beta.2/scss/_input-group.scss
   */
}

.input-group > .select2-container > .selection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.input-group > .select2-container > .selection > .select2-selection.form-control {
  float: none;
}

.input-group > .select2-container.select2-container--open, .input-group > .select2-container.select2-container--focus {
  z-index: 3;
}

.input-group > .select2-container,
.input-group > .select2-container .input-group-append,
.input-group > .select2-container .input-group-prepend,
.input-group > .select2-container .input-group-append .btn,
.input-group > .select2-container .input-group-prepend .btn {
  vertical-align: top;
}

/**
 * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
 *
 * Provides `!important` for certain properties of the class applied to the
 * original `<select>` element to hide it.
 *
 * @see https://github.com/select2/select2/pull/3301
 * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
 */
.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

/**
 * Display override for inline forms
 */
@media (min-width: 576px) {
  .form-inline .select2-container {
    display: inline-block;
  }
}

.sonata-bc .select2-container .select2-selection--single,
.sonata-bc .form-control {
    border: 1px solid $input-border-color;
}

.sonata-bc .card {
    border: 1px solid rgba(0, 0, 0, 0.15);
}
